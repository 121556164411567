.preview-wrapper {
  position: relative;
  height: 100%;
  flex-grow: 1;
}

.preview-wrapper iframe {
  height: 100%;
  width: 100%;
}
.preview-error {
  position: absolute;
  top: 10px;
  left: 10px;
  color: red;
}
.react-draggable-transparent-selection .preview-wrapper:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}
