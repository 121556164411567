.editor-header {
  height: 40px;
  background-color: #333;
  padding-left: 25px;
}

.MuiIconButton-root {
  background-color: rgba(0, 0, 0, 0) !important;
  color: white !important;
}
